import { React, ProductTile, Text, Button } from "xolabot-sdk";
import getDiscount from "../utils/getDiscount";

const PackageColumn = ({ pack, compact, onUpgradeNowClick }) => (
    <Text align="center">
        <div style={{ marginBottom: 30 }}>
            <Text transform="uppercase" gutter="tiny" weight="bold">
                {pack.name}
            </Text>

            {getDiscount(pack) ? (
                <Text size="small" gutter="medium">
                    Save up to {getDiscount(pack)} when you upgrade
                </Text>
            ) : null}

            {pack.experiences.map((experience, index) => (
                <React.Fragment key={index}>
                    <ProductTile
                        key={index}
                        image={experience.defaultPhotoUrl}
                        name={experience.name}
                        description={experience.excerpt}
                        compact={compact}
                    />

                    {index < pack.experiences.length - 1 ? (
                        <div style={{ padding: 10, fontWeight: "bold" }}>+</div>
                    ) : null}
                </React.Fragment>
            ))}
        </div>

        <Button onClick={onUpgradeNowClick}>Upgrade Now</Button>
    </Text>
);

export default PackageColumn;
