const getPrice = (basePrice, discount) => {
    return discount ? basePrice - discount.amount : basePrice;
};

const getExperiencePrices = (experience) => {
    const priceSchema = experience.priceSchemes.find((priceSchema) => {
        return priceSchema.privacy === "public" && priceSchema.priceType === "person";
    });

    if (!priceSchema) {
        return [];
    }

    return experience.demographics.map((demographic) => {
        return {
            demographic: demographic,
            price: getPrice(priceSchema.price, demographic.discount),
        };
    });
};

export default getExperiencePrices;
