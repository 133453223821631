import { React, Columns, Text } from "xolabot-sdk";
import PackageColumn from "./PackageColumn";

const ModalBody = ({ packages, onUpgradeNowClick, onCancelClick }) => (
    <div>
        <Columns style={{ marginBottom: 20 }}>
            {packages.map((pack, index) => (
                <PackageColumn
                    onUpgradeNowClick={() => onUpgradeNowClick(pack)}
                    key={index}
                    pack={pack}
                    compact={packages.length > 1}
                />
            ))}
        </Columns>

        <Text
            onClick={onCancelClick}
            style={{ cursor: "pointer" }}
            decoration="underline"
            align="center"
            size="tiny"
            weight="bold"
        >
            No, thanks
        </Text>
    </div>
);

export default ModalBody;
