import ModalBody from "./components/ModalBody";
import Module, { React } from "xolabot-sdk";
import memoize from "lodash/memoize";
import config from "./config.json";

class UpsellPackagesModule extends Module {
    static APP_ID = config.plugin.id;
    selectedPackage = null;
    purchased = false;
    fetchingData = false;

    experiencePackages = [];
    availablePackages = [];
    packagesWithExperiences = [];

    constructor(options) {
        super("package_upsell", options);

        // X1
        this.stopHandleOrderInfo = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: "order_info" },
            this.handleOrderInfo,
        );

        this.stopHandleTagOrders = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: "order_info" },
            this.handleTagOrdersAndPurchases,
        );

        // X2
        this.stopHandlePurchaseInfo = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: "purchase_info" },
            this.handlePurchaseInfo,
        );

        this.stopHandleTagPurchase = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: "purchase_info" },
            this.handleTagOrdersAndPurchases,
        );

        // X1
        this.bus.on({ channel: this.channels.CHECKOUT_COLLECTOR, type: "order_info" }, this.handleDetectPurchase);

        // X2
        this.bus.on({ channel: this.channels.CHECKOUT_COLLECTOR, type: "purchase_info" }, this.handleDetectPurchase);

        this.bus.on({ channel: this.channels.CHECKOUT, type: "closed" }, this.handleDetectCheckoutClosed);
    }

    handleDetectCheckoutClosed = () => {
        if (this.selectedPackage) {
            this.selectedPackage = null;
            if (!this.purchased) {
                this.drop();
            }
        }
    };

    handleDetectPurchase = ({ purchased }) => {
        if (this.selectedPackage && purchased) {
            this.purchased = true;
            this.stopHandleTagOrders();
            this.stopHandleTagPurchase();
        }
    };

    // For X1 and X2
    handleTagOrdersAndPurchases = ({ reached_payment_page }) => {
        if (reached_payment_page && this.selectedPackage) {
            this.checkout.xwm.checkout.send("tag_orders", "XolaBot");
            this.checkout.xwm.checkout.send("tag_orders", "XolaBot - Upsell Packages");
            this.checkout.xwm.checkout.send("tag_purchases", "XolaBot");
            this.checkout.xwm.checkout.send("tag_purchases", "XolaBot - Upsell Packages");
        }
    };

    fetchExperience = memoize((experienceId) => {
        return this.api.get(`/api/experiences/${experienceId}`).then((response) => {
            const uri = `/api/experiences/${experienceId}/medias/default?size=medium`;
            return {
                ...response.data,
                defaultPhotoUrl: `${this.api.defaults.baseURL}${uri}`,
            };
        });
    });

    // X1
    fetchExperiencesForPackage = (pack) => {
        return Promise.all(
            pack.triggers[0].experiences.map((experience) => {
                return this.fetchExperience(experience.id);
            }),
        );
    };

    // X2
    fetchExperiencesForPackageX2 = (pkg) => {
        return Promise.all(
            pkg.selectedProducts.map((experience) => {
                return this.fetchExperience(experience.id);
            }),
        );
    };

    // X1
    async fetchAvailabilities(orderInfo, packages) {
        const promises = packages.map(async (pack) => {
            const { data } = await this.api.get(`/api/experiences/${orderInfo.experience_id}/availability`, {
                params: {
                    package: pack.id,
                    privacy: "public",
                    start: orderInfo.arrival_date,
                    end: orderInfo.arrival_date,
                },
            });

            let available = false;

            if (data[orderInfo.arrival_date]) {
                // Using "0" to cover all-day experiences.
                const arrivalTime = orderInfo.arrival_time || 0;
                if (data[orderInfo.arrival_date][arrivalTime]) {
                    available = data[orderInfo.arrival_date][arrivalTime] >= orderInfo.demographics_quantity;
                }
            }

            return available ? pack : null;
        });

        return (await Promise.all(promises)).filter((pack) => pack).slice(0, 3);
    }

    // For X2
    async fetchTimeslotAvailabilities(purchaseInfo, packages) {
        const promises = packages.map(async (pack) => {
            const params = {
                product: purchaseInfo.experience_id,
                seller: purchaseInfo.seller_id,
                package: pack.id,
                privacy: "public",
            };
            if (purchaseInfo.arrival_date) {
                params.start = [purchaseInfo.arrival_date, purchaseInfo.arrival_date].join(",");
            }

            const { data } = await this.api.get(`/api/timeslots`, { params });
            let available = false;
            if (data && data.data.length > 0) {
                const availableTimeslot = data.data.filter((timeslot) => {
                    const arrivalTime = purchaseInfo.arrival_time || null;
                    if (timeslot.time === arrivalTime) {
                        return timeslot.capacity.open >= purchaseInfo.demographics_quantity;
                    }
                });

                available = availableTimeslot.length > 0;
            }

            return available ? pack : null;
        });

        return (await Promise.all(promises)).filter((pack) => pack).slice(0, 3);
    }

    async fetchPackages(sellerId, packageIds) {
        const { data } = await this.api.get("/api/packages", { params: { seller: sellerId } });
        return data.data.filter((pack) => packageIds.indexOf(pack.id) >= 0);
    }

    // X1
    experiencePackagesExist(experienceId) {
        return this.experiencePackages.some((experiencePackage) =>
            experiencePackage.triggers.some(
                (trigger) =>
                    trigger.object === "experience_trigger" &&
                    trigger.experiences.some((experience) => experience.id === experienceId),
            ),
        );
    }

    // X2
    experiencePackagesExistX2(experienceId) {
        return this.experiencePackages.some((experiencePackage) =>
            experiencePackage.selectedProducts.some((experience) => experience.id === experienceId),
        );
    }

    // For X1
    handleOrderInfo = async (orderInfo) => {
        if (
            orderInfo.item_count === 1 &&
            orderInfo.experience_id &&
            !this.experiencePackagesExist(orderInfo.experience_id) &&
            !this.fetchingData
        ) {
            this.fetchingData = true;

            // Check if Package Upsell is configured for this experience.
            const experience = this.config.experiences && this.config.experiences[orderInfo.experience_id];
            if (!experience) {
                return;
            }

            // Fetch all packages configured for this experience.
            this.experiencePackages = await this.fetchPackages(orderInfo.seller_id, experience.packages);

            // Check which packages are available for selected date/time.
            this.availablePackages = await this.fetchAvailabilities(orderInfo, this.experiencePackages);

            // Fetch experience info for available packages.
            this.packagesWithExperiences = await Promise.all(
                this.availablePackages.map(async (pack) => {
                    const experiences = await this.fetchExperiencesForPackage(pack);
                    return { ...pack, experiences };
                }),
            );

            this.fetchingData = false;
            this.experiencePackages = [];
        }

        if (orderInfo.item_count === 1 && orderInfo.reached_payment_page && orderInfo.experience_id) {
            // Check if Package Upsell is configured for this experience.
            const experience = this.config.experiences && this.config.experiences[orderInfo.experience_id];
            if (!experience) {
                return;
            }

            this.stopHandleOrderInfo();

            if (this.availablePackages.length === 0) {
                return;
            }

            const score = { confidence: 0.6, value: 10 };
            this.notify(score, {
                type: "modal",
                message: "Upgrade to a package deal and get a discount",
                render: () => (
                    <ModalBody
                        packages={this.packagesWithExperiences}
                        onUpgradeNowClick={this.handleUpgradeNowClick}
                        onCancelClick={this.handleCancelClick}
                    />
                ),
            });
        }
    };

    // For X2
    handlePurchaseInfo = async (purchaseInfo) => {
        if (
            purchaseInfo.item_count === 1 &&
            purchaseInfo.experience_id &&
            !this.experiencePackagesExistX2(purchaseInfo.experience_id) &&
            !this.fetchingData
        ) {
            this.fetchingData = true;

            // Check if Package Upsell is configured for this experience.
            const experience = this.config.experiences && this.config.experiences[purchaseInfo.experience_id];
            if (!experience) {
                return;
            }

            // Fetch all packages configured for this experience.
            this.experiencePackages = await this.fetchPackages(purchaseInfo.seller_id, experience.packages);

            // Check which packages are available for selected date/time.
            this.availablePackages = await this.fetchTimeslotAvailabilities(purchaseInfo, this.experiencePackages);

            // Fetch experience info for available packages.
            this.packagesWithExperiences = await Promise.all(
                this.availablePackages.map(async (pack) => {
                    const experiences = await this.fetchExperiencesForPackageX2(pack);
                    return { ...pack, experiences };
                }),
            );

            this.fetchingData = false;
            this.experiencePackages = [];
        }

        if (purchaseInfo.item_count === 1 && purchaseInfo.reached_payment_page && purchaseInfo.experience_id) {
            // Check if Package Upsell is configured for this experience.
            const experience = this.config.experiences && this.config.experiences[purchaseInfo.experience_id];
            if (!experience) {
                return;
            }

            this.stopHandlePurchaseInfo();

            if (this.availablePackages.length === 0) {
                return;
            }

            const score = { confidence: 0.6, value: 10 };
            this.notify(score, {
                type: "modal",
                message: "Upgrade to a package deal and get a discount",
                render: () => (
                    <ModalBody
                        packages={this.packagesWithExperiences}
                        onUpgradeNowClick={this.handleUpgradeNowClick}
                        onCancelClick={this.handleCancelClick}
                    />
                ),
            });
        }
    };

    handleCancelClick = () => {
        this.bus.emit({ channel: this.channels.UI, type: "close_modal" }, { name: this.name });
        this.drop();
    };

    // For X1 and X2
    handleUpgradeNowClick = (pack) => {
        this.selectedPackage = pack;
        this.bus.emit({ channel: this.channels.UI, type: "close_modal" }, { name: this.name });
        this.checkout.xwm.checkout.send("remove_orders");
        this.checkout.xwm.checkout.send("remove_purchases");
        this.checkout.checkout({ seller: pack.seller.id, package: pack.id });
    };
}

UpsellPackagesModule.load();
