import getExperiencePrices from "./getExperiencePrices";
import { format } from "./currencyHelper";

const getDiscount = (discount, basePrice) => {
    if (discount.amountType === "percent") {
        return (discount.amount / 100) * basePrice;
    }

    if (discount.amountType === "absolute") {
        return discount.amount;
    }

    return basePrice - discount.amount;
};

const getRegularPrice = (pack) => {
    const regularPrices = pack.experiences.map((experience) => {
        const priceSchema = experience.priceSchemes.find((priceSchema) => {
            return priceSchema.privacy === "public" && priceSchema.priceType === "person";
        });

        return priceSchema ? priceSchema.price : 0;
    }, 0);

    if (regularPrices.some((regularPrice) => !regularPrice)) {
        return null;
    }

    return regularPrices.reduce((sum, regularPrice) => sum + regularPrice, 0);
};

const getBiggestDiscount = (rewards, experiences) => {
    return rewards.reduce((biggestDiscount, reward) => {
        const price = experiences.reduce((price, experience) => {
            const experiencePrices = getExperiencePrices(experience);

            const experiencePrice = experiencePrices.find(({ demographic }) => {
                return reward.demographic.id === demographic.id || reward.demographic.id === demographic.parent.id;
            });

            return experiencePrice ? price + experiencePrice.price : price;
        }, 0);

        const discount = getDiscount(reward.discount, price);

        return biggestDiscount < discount ? discount : biggestDiscount;
    }, 0);
};

const getDiscountText = (pack) => {
    try {
        const regularPrice = getRegularPrice(pack);

        if (!regularPrice) {
            return null;
        }

        const rewards = pack.rewards.filter((reward) => reward.object === "demographic_reward");
        const biggestDiscount = Math.round(getBiggestDiscount(rewards, pack.experiences));
        const percent = Math.round((biggestDiscount / regularPrice) * 100);

        return `${percent}% or ${format(biggestDiscount,pack.experiences[0].currency)} per person`;
    } catch (e) {
        return null;
    }
};

export default getDiscountText;
